import React, {useMemo} from 'react';
import styles from './CompaniesBarChartDashboard.module.scss';
import {
  ActiveCompanyData,
  CompanyFinancialData,
  DataVisualisaitonChartType,
  KpiFilterOption,
  SelectOption,
} from '../../../types';
import {filterCompaniesBarChartData} from '../../../utils';
import {
  BarChartWidget,
  FilterSection,
  SelectDropdown,
  MultiRangeSlider,
  LoadingOverlay,
} from '../../global';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../../hooks/useReduxHooks';
import {
  getActiveCompaniesQuarterlyDataAction,
  updateChartFilter,
} from '../../../reducers/portfolioCompaniesChartDataSlice';
import {useHistory} from 'react-router-dom';
import routePaths from '../../../routePaths';

interface CompaniesBarChartDashboardProps {
  data: ActiveCompanyData[];
  filterData: KpiFilterOption[];
  barChartDataRequestPending: boolean;
}
const CompaniesBarChartDashboard: React.FC<CompaniesBarChartDashboardProps> = ({
  data,
  filterData,
  barChartDataRequestPending,
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const filteredChartData = useMemo(() => {
    return filterCompaniesBarChartData(data, filterData);
  }, [data, filterData]);

  const barChartTimeSeries = useMemo(() => {
    return filteredChartData
      .map(item => {
        const yAxisKey = filterData.find(item => item.name === 'yAxis');
        return {
          x: item.companyName,
          y: yAxisKey?.value
            ? (
                item[
                  yAxisKey.value as keyof ActiveCompanyData
                ] as CompanyFinancialData
              )?.value
            : null,
          additionalData: {
            id: item.id,
          },
        };
      })
      .filter(item => {
        const rangeFilter = filterData.find(item => item.name === 'yAxisRange');
        if (!rangeFilter) return true;
        if (item.y === null) return false;
        return (
          item.y >= (rangeFilter.value as number[])[0] &&
          item.y <= (rangeFilter.value as number[])[1]
        );
      })
      .sort((a, b) => (a.y || 0) - (b.y || 0));
  }, [filteredChartData, filterData]);

  const filterDataForSection = useMemo(
    () =>
      filterData.filter(
        item => item.name !== 'yAxis' && item.name !== 'yAXisRange'
      ),
    [filterData]
  );

  const {yAxisFilterData, yAxisFilterRange} = useMemo(() => {
    const yAxisFilterData = filterData.find(item => item.name === 'yAxis');
    const yAxisFilterRange = filterData.find(
      item => item.name === 'yAxisRange'
    );
    return {yAxisFilterData, yAxisFilterRange};
  }, [filterData]);

  const handleChangeFilter = (payload: {
    name: string;
    value: string | number | string[] | number[];
  }) => {
    if (payload.name === 'asAt') {
      dispatch(
        getActiveCompaniesQuarterlyDataAction(
          payload.name,
          payload.value as string,
          DataVisualisaitonChartType.BarChart
        )
      );
    }
    dispatch(
      updateChartFilter({
        ...payload,
        chartType: DataVisualisaitonChartType.BarChart,
      })
    );
  };

  const handleClickBar = ({id}: {id: string}) => {
    history.push(`${routePaths.PORTFOLIO_COMPANIES}/${id}/investment-overview`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.filterWrapper}>
        <FilterSection
          config={filterDataForSection}
          handleChange={handleChangeFilter}
          isDisabled={barChartDataRequestPending}
        />
      </div>
      <div className={styles.additionalFilter}>
        <div className={styles.additionalFilterInner}>
          <div className={styles.additionalFilterLabel}>
            {t('Global.YAxis')}:
          </div>
          {yAxisFilterData && (
            <SelectDropdown
              id={''}
              className={styles.select}
              onChange={value => handleChangeFilter({name: 'yAxis', value})}
              options={yAxisFilterData.options as SelectOption[]}
              value={yAxisFilterData.value as string}
              disabled={barChartDataRequestPending}
            />
          )}
          {yAxisFilterRange && (
            <MultiRangeSlider
              min={yAxisFilterRange.options[0] as number}
              max={yAxisFilterRange.options[1] as number}
              minValue={(yAxisFilterRange.value as number[])[0]}
              maxValue={(yAxisFilterRange.value as number[])[1]}
              step={10}
              format={'auto'}
              onChange={({max, min}) =>
                handleChangeFilter({name: 'yAxisRange', value: [min, max]})
              }
            />
          )}
        </div>
      </div>
      <div className={styles.overlayWrapper}>
        <BarChartWidget<Pick<ActiveCompanyData, 'id'>>
          className={styles.barChart}
          timeSeries={barChartTimeSeries}
          yAxisFormat={'auto'}
          onBarClick={handleClickBar}
        />
        {barChartDataRequestPending ? <LoadingOverlay /> : null}
      </div>
    </div>
  );
};

export default CompaniesBarChartDashboard;
