import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import classnames from 'classnames';
import {
  CompaniesBarChartDashboard,
  CompaniesBubbleChartDashboard,
  CompaniesTreemapChartDashboard,
  CompanyWidget,
  IconButton,
  SectionHeader,
  SegmentedControl,
  SelectDropdown,
  Tabs,
} from '../../';
import {
  fundListSwitchOptions,
  fundListSwitchOptionsForActiveCompanies,
  portfolioInvestmentsTabs,
  activeCompaniesSortingOptions,
  exitedCompaniesSortingOptions,
  writtenOffCompaniesSortingOptions,
} from '../../../constants';
import {
  ActiveDashboardView,
  ButtonStyle,
  CompaniesSortPayload,
  CompaniesTiles,
  CompanyStatus,
  Func,
  PortfolioCompaniesChartData,
  Tab,
} from '../../../types';
import {SegmentedControlVariants} from '../../global/segmented-control/SegmentedControl';
import styles from './AllCompaniesDashboard.module.scss';
import {useTranslation} from 'react-i18next';

interface AllCompaniesDashboardProps {
  id: string;
  tiles: CompaniesTiles | null;
  tables: {
    active: ReactElement;
    exited: ReactElement;
    writtenOff: ReactElement;
  };
  className?: string;
  onCompaniesSort?: Func<[CompaniesSortPayload], void>;
  exportHandler?: Func<[ActiveDashboardView, Tab], void>;
  exportInProgress?: boolean;
  chartData: PortfolioCompaniesChartData;
  releaseCompanyDataVisualisationEnabled: any;
}

type SortValue = {
  [key in CompanyStatus]: string;
};

const AllCompaniesDashboard: React.FC<AllCompaniesDashboardProps> = ({
  id,
  tiles,
  tables,
  className = '',
  onCompaniesSort,
  exportHandler = () => null,
  exportInProgress,
  chartData,
  releaseCompanyDataVisualisationEnabled,
}): JSX.Element => {
  const {t} = useTranslation();
  const [activeTab, setActiveTab] = useState<Tab>(portfolioInvestmentsTabs[0]);
  const [activeView, setActiveView] = useState(ActiveDashboardView.Table);
  const [sortState, setSortState] = useState<SortValue>({
    active: activeCompaniesSortingOptions[0][0].value,
    exited: exitedCompaniesSortingOptions[0][0].value,
    writtenOff: writtenOffCompaniesSortingOptions[0][0].value,
  });

  useEffect(() => {
    if (
      activeTab.value !== 'active' &&
      (activeView === ActiveDashboardView.BarChart ||
        activeView === ActiveDashboardView.TreemapChart ||
        activeView === ActiveDashboardView.ScatteredBubbles)
    )
      setActiveView(ActiveDashboardView.Table);
  }, [activeTab.value, activeView]);

  const {current, options} = useMemo(() => {
    if (!tiles) {
      return {
        current: null,
        options: activeCompaniesSortingOptions,
      };
    }

    if (activeTab.value === 'active') {
      return {
        current: tiles.active,
        options: activeCompaniesSortingOptions,
      };
    }
    if (activeTab.value === 'exited') {
      return {
        current: tiles.exited,
        options: exitedCompaniesSortingOptions,
      };
    }
    return {
      current: tiles.writtenOff,
      options: writtenOffCompaniesSortingOptions,
    };
  }, [activeTab.value, tiles]);

  const handleChangeSorting = (value: string) => {
    setSortState(prev => ({
      ...prev,
      [activeTab.value as CompanyStatus]: value,
    }));
    if (onCompaniesSort) {
      const [sortValue, sortDirection] = value.split('-');
      onCompaniesSort({
        status: activeTab.value as CompanyStatus,
        sortValue,
        sortDirection,
      });
    }
  };

  return (
    <div id={id} className={classnames(styles.wrapper, className)}>
      <SectionHeader
        onClick={() => null}
        withActionButton={false}
        className={styles.sectionHeader}
        childrenLeft={[
          <Tabs
            tabs={portfolioInvestmentsTabs}
            activeTab={activeTab}
            clickHandler={tab => setActiveTab(tab)}
          />,
        ]}
        childrenRight={[
          activeView === ActiveDashboardView.Tiles ? (
            <div className={styles.selectDropdownWrapper}>
              <SelectDropdown
                id={`${id}-select-dropdown-sort-by`}
                onChange={value => handleChangeSorting(value as string)}
                options={options}
                value={sortState[activeTab.value as CompanyStatus]}
                label={t('Global.SortBy')}
                fullContent
              />
            </div>
          ) : null,
          <SegmentedControl
            key={
              activeTab.value === 'active'
                ? 'active-companies'
                : 'other-companies'
            }
            id={`${id}-segmented-control-view-options`}
            options={
              activeTab.value === 'active' &&
              !!releaseCompanyDataVisualisationEnabled
                ? fundListSwitchOptionsForActiveCompanies
                : fundListSwitchOptions
            }
            onChange={val => setActiveView(val as ActiveDashboardView)}
            value={activeView}
            variant={SegmentedControlVariants.Secondary}
            disabled={!tiles}
          />,
          <IconButton
            id={`${id}-export`}
            onClick={() => exportHandler(activeView, activeTab)}
            styleType={ButtonStyle.Primary}
            icon="export"
            loading={exportInProgress}
            title="Export"
          />,
        ]}
      />
      {activeView === ActiveDashboardView.Tiles && (
        <div className={styles.tileWrapper}>
          {current?.map((item, i) => {
            return (
              <CompanyWidget
                {...item}
                sortingValue={
                  sortState[activeTab.value as CompanyStatus].split('-')[0]
                }
                key={i}
              />
            );
          })}
        </div>
      )}
      {activeView === ActiveDashboardView.Table && (
        <div className={styles.tableWrapper}>
          {/*  ActivePortfolioCompaniesTable */}
          {activeTab.label === 'Global.Active' && tables.active}
          {/* ExitedPortfolioCompaniesTable */}
          {activeTab.label === 'Global.Exited' && tables.exited}
          {/* WrittenOffPortfolioCompaniesTable */}
          {activeTab.label === 'Global.WrittenOff' && tables.writtenOff}
        </div>
      )}
      {activeView === ActiveDashboardView.BarChart && (
        <div className={styles.chartDashboardContainer}>
          <CompaniesBarChartDashboard
            data={chartData.barChartData || []}
            filterData={chartData.barChartFilter || []}
            barChartDataRequestPending={chartData.barChartDataRequestPending}
          />
        </div>
      )}
      {activeView === ActiveDashboardView.TreemapChart && (
        <div className={styles.chartDashboardContainer}>
          <CompaniesTreemapChartDashboard
            data={chartData.treemapData || []}
            filterData={chartData.treemapFilter || []}
            treemapDataRequestPending={chartData.treemapDataRequestPending}
          />
        </div>
      )}
      {activeView === ActiveDashboardView.ScatteredBubbles && (
        <div className={styles.chartDashboardContainer}>
          <CompaniesBubbleChartDashboard
            data={chartData.bubbleChartData || []}
            filterData={chartData.bubbleChartFilter || []}
            bubbleChartDataRequestPending={
              chartData.bubbleChartDataRequestPending
            }
          />
        </div>
      )}
    </div>
  );
};

export default AllCompaniesDashboard;
