import {configureStore} from '@reduxjs/toolkit';
import {History} from 'history';
import createSagaMiddleware from 'redux-saga';
import {createLogger} from 'redux-logger';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import createRootReducer from './reducer';
import rootSaga from './saga';
import config from './config';

// initialize history
export const history = createBrowserHistory({basename: config.PUBLIC_URL});
/**
 * Creates Redux store from seed state and root reducer.
 * Then configures it by applying middleware and attaching helper methods
 *
 * @param {Object} initialState
 * @param {Object} history
 * @returns {Object}
 */
const configureAppStore = (initialState: {}, history: History) => {
  const sagaMiddleware = createSagaMiddleware();
  let middleware = [routerMiddleware(history), sagaMiddleware];

  // if in development and in browser log redux actions
  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    middleware = [...(middleware as any), createLogger()];
  }

  const store = configureStore({
    reducer: createRootReducer(history),
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ['documents/document-explorer/upload-file'],
        },
      }).concat(middleware),
  });

  // webpack hot module replacement for reducers
  // if (module.hot) {
  //     module.hot.accept('./reducer', () => {
  //         const nextCreateRootReducer = require('./reducer').default;
  //         store.replaceReducer(nextCreateRootReducer(history));
  //     });
  // }

  // store helper methods
  // store.runSaga = sagaMiddleware.run;
  sagaMiddleware.run(rootSaga);
  // store.close = () => store.dispatch(END);
  return store;
};

// initialize store
const store = configureAppStore({}, history);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
