import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ActiveCompanyData,
  DataVisualisaitonChartType,
  PCChartDataLoadingKey,
  PortfolioCompaniesChartData,
  QuarterlyDataAvailability,
} from '../types/';
import {
  generateChartFilterConfig,
  getDataVisualisationChartDataKeys,
  handleChangeFilterCompaniesFilter,
} from '../utils/';

export const getActiveCompaniesQuarterlyDataAction = createAction(
  'companies/get-active-companies-data',
  (name: string, value: string, chartType: DataVisualisaitonChartType) => {
    return {
      payload: {
        name,
        value,
        chartType,
      },
    };
  }
);

interface PortfolioCompaniesChartDataSlice {
  data: PortfolioCompaniesChartData;
}

const initialState: PortfolioCompaniesChartDataSlice = {
  data: {
    barChartData: null,
    barChartFilter: null,
    treemapData: null,
    treemapFilter: null,
    bubbleChartData: null,
    bubbleChartFilter: null,
    barChartDataRequestPending: false,
    treemapDataRequestPending: false,
    bubbleChartDataRequestPending: false,
  },
};

const portfolioCompaniesChartDataSlice = createSlice({
  name: 'portfolio-companies-chart-data',
  initialState,
  reducers: {
    setPortfolioCompaniesChartData(
      state: PortfolioCompaniesChartDataSlice,
      action: PayloadAction<{
        companies: ActiveCompanyData[];
        quarterlyData: QuarterlyDataAvailability;
        chartTypes: DataVisualisaitonChartType[];
      }>
    ) {
      action.payload.chartTypes.forEach(chart => {
        const {chartKey, filterKey, filterKpis} =
          getDataVisualisationChartDataKeys(chart);

        const filter = generateChartFilterConfig(
          action.payload.companies,
          filterKpis,
          action.payload.quarterlyData
        );

        state.data[chartKey] = action.payload.companies;
        state.data[filterKey] = filter;
      });
    },
    updateChartFilter(
      state: PortfolioCompaniesChartDataSlice,
      action: PayloadAction<{
        name: string;
        value: string | number | string[] | number[];
        chartType: DataVisualisaitonChartType;
      }>
    ) {
      const {chartKey, filterKey} = getDataVisualisationChartDataKeys(
        action.payload.chartType
      );

      if (state.data && state.data[filterKey] && state.data[chartKey]) {
        // Apply filtering
        state.data[filterKey] = handleChangeFilterCompaniesFilter(
          action.payload.name,
          action.payload.value,
          state.data[filterKey] || [],
          state.data[chartKey] || []
        );
      }
    },
    setChartDataRequestPending(
      state: PortfolioCompaniesChartDataSlice,
      action: PayloadAction<{
        requestPendingKey: PCChartDataLoadingKey;
        isLoading: boolean;
      }>
    ) {
      const {requestPendingKey, isLoading} = action.payload;
      state.data[requestPendingKey] = isLoading;
    },
  },
});

export const {
  setPortfolioCompaniesChartData,
  updateChartFilter,
  setChartDataRequestPending,
} = portfolioCompaniesChartDataSlice.actions;
export default portfolioCompaniesChartDataSlice.reducer;
