import React from 'react';
import {TooltipProps} from 'recharts/index';
import styles from './BarChartTooltip.module.scss';
import {useTranslation} from 'react-i18next';
import {valueFormat} from '../../../../utils';

type BarTooltipProps = Pick<TooltipProps, 'active' | 'payload'>;

const BarChartTooltip: React.FC<BarTooltipProps> = ({active, payload}) => {
  const {t} = useTranslation();
  if (active && payload?.length) {
    const getPayload = payload[0].payload;
    const getValue = getPayload.y;
    const getLabel = getPayload.x;
    return (
      <div className={styles.tooltipWrapper}>
        <div className={styles.labelWrapper}>{getLabel}</div>
        <div className={styles.valueWrapper}>
          <b>
            {t('Global.Value')}
            {': '}
          </b>
          <span>{valueFormat(getValue, 'auto').value}</span>
        </div>
      </div>
    );
  }

  return null;
};

export default BarChartTooltip;
