import {
  ActiveCompanyData,
  CompanyFinancialData,
  DataVisualisaitonChartType,
  KpiFilterOption,
  SelectOption,
  TreemapChartNodeData,
} from '../../../types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useAppDispatch} from '../../../hooks/useReduxHooks';
import {filterCompaniesBarChartData} from '../../../utils';
import {
  getActiveCompaniesQuarterlyDataAction,
  updateChartFilter,
} from '../../../reducers/portfolioCompaniesChartDataSlice';
import routePaths from '../../../routePaths';
import styles from './CompaniesTreemapChartDashboard.module.scss';
import {
  FilterSection,
  LoadingOverlay,
  MultiRangeSlider,
  SelectDropdown,
  TreemapChartWidget,
} from '../../global';

interface CompaniesTreemapChartDashboardProps {
  data: ActiveCompanyData[];
  filterData: KpiFilterOption[];
  treemapDataRequestPending: boolean;
}
const CompaniesTreemapChartDashboard: React.FC<
  CompaniesTreemapChartDashboardProps
> = ({data, filterData, treemapDataRequestPending}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const filteredChartData = useMemo(
    () => filterCompaniesBarChartData(data, filterData),
    [data, filterData]
  );

  const {treemapChartTimeSeries, advancedFilters, colorLabel, tileScaleLabel} =
    useMemo(() => {
      const tileScaleKey = filterData.find(item => item.name === 'yAxis');
      const colorKey = filterData.find(item => item.name === 'xAxis');
      const colorRangeFilter = filterData.find(
        item => item.name === 'xAxisRange'
      );
      const tileScaleRangeFilter = filterData.find(
        item => item.name === 'yAxisRange'
      );

      const treemapChartTimeSeries: TreemapChartNodeData<
        Pick<ActiveCompanyData, 'id'>
      >[] = filteredChartData
        .map(
          item => ({
            colorValue: colorKey?.value
              ? (
                  item[
                    colorKey.value as keyof ActiveCompanyData
                  ] as CompanyFinancialData
                )?.value
              : null,
            tileScaleValue: tileScaleKey?.value
              ? (
                  item[
                    tileScaleKey.value as keyof ActiveCompanyData
                  ] as CompanyFinancialData
                )?.value
              : null,
            title: item.companyName,
            additionalData: {
              id: item.id,
            },
          }),
          {name: '', children: []}
        )
        .filter(item => {
          const byXrange =
            colorRangeFilter && item.colorValue
              ? item.colorValue >= (colorRangeFilter.value as number[])[0] &&
                item.colorValue <= (colorRangeFilter.value as number[])[1]
              : true;
          const byYrange =
            tileScaleRangeFilter && item.tileScaleValue
              ? item.tileScaleValue >=
                  (tileScaleRangeFilter.value as number[])[0] &&
                item.tileScaleValue <=
                  (tileScaleRangeFilter.value as number[])[1]
              : true;
          return byXrange && byYrange;
        });

      const advancedFilters = [
        {
          data: tileScaleKey,
          range: tileScaleRangeFilter,
          label: 'Global.TileScale',
          dataName: 'yAxis',
          rangeName: 'yAxisRange',
        },
        {
          data: colorKey,
          range: colorRangeFilter,
          label: 'Global.Colour',
          dataName: 'xAxis',
          rangeName: 'xAxisRange',
        },
      ];
      const colorLabel = (colorKey?.options as SelectOption[]).find(
        item => item.value === colorKey?.value
      )?.label;
      const tileScaleLabel = (tileScaleKey?.options as SelectOption[]).find(
        item => (item as SelectOption).value === tileScaleKey?.value
      )?.label;
      return {
        treemapChartTimeSeries,
        advancedFilters,
        colorLabel,
        tileScaleLabel,
      };
    }, [filteredChartData, filterData]);

  const filterDataForSection = useMemo(
    () =>
      filterData.filter(
        item =>
          item.name !== 'yAxis' &&
          item.name !== 'yAXisRange' &&
          item.name !== 'xAxis' &&
          item.name !== 'xAxisRange'
      ),
    [filterData]
  );

  const handleChangeFilter = (payload: {
    name: string;
    value: string | number | string[] | number[];
  }) => {
    if (payload.name === 'asAt') {
      dispatch(
        getActiveCompaniesQuarterlyDataAction(
          payload.name,
          payload.value as string,
          DataVisualisaitonChartType.TreemapChart
        )
      );
    }
    dispatch(
      updateChartFilter({
        ...payload,
        chartType: DataVisualisaitonChartType.TreemapChart,
      })
    );
  };

  const handleClickNode = ({id}: {id: string}) => {
    history.push(`${routePaths.PORTFOLIO_COMPANIES}/${id}/investment-overview`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.filterWrapper}>
        <FilterSection
          config={filterDataForSection}
          handleChange={handleChangeFilter}
          isDisabled={treemapDataRequestPending}
        />
      </div>
      <div className={styles.additionalFilter}>
        {advancedFilters.map(
          ({data, range, label, dataName, rangeName}, index) =>
            data && range ? (
              <div className={styles.additionalFilterInner} key={index}>
                <div className={styles.additionalFilterLabel}>{t(label)}:</div>
                <SelectDropdown
                  id={''}
                  className={styles.select}
                  onChange={value =>
                    handleChangeFilter({name: dataName, value})
                  }
                  options={data.options as SelectOption[]}
                  value={data.value as string}
                  disabled={treemapDataRequestPending}
                />
                <MultiRangeSlider
                  min={range.options[0] as number}
                  max={range.options[1] as number}
                  minValue={(range.value as number[])[0]}
                  maxValue={(range.value as number[])[1]}
                  step={0.01}
                  format={'auto'}
                  onChange={({max, min}) =>
                    handleChangeFilter({name: rangeName, value: [min, max]})
                  }
                />
              </div>
            ) : null
        )}
      </div>
      <div className={styles.overlayWrapper}>
        <TreemapChartWidget<Pick<ActiveCompanyData, 'id'>>
          className={styles.chart}
          timeSeries={{name: '', children: treemapChartTimeSeries}}
          colorLabel={colorLabel as string | undefined}
          tileScaleLabel={tileScaleLabel as string | undefined}
          onClickNode={handleClickNode}
        />
        {treemapDataRequestPending ? <LoadingOverlay /> : null}
      </div>
    </div>
  );
};

export default CompaniesTreemapChartDashboard;
