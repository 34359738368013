import React, {Fragment, useState} from 'react';
import {CompanyData, Func} from '../../../types/index';
import {
  CompanyWidget,
  PerformingCompaniesStats,
  SectionHeader,
  SelectDropdown,
} from '../../';
import {dashboardCompaniesSortingOptions} from '../../../constants';
import CompanyWidgetSkeleton from '../company-widget/CompanyWidgetSkeleton';
import styles from './CompaniesDashboard.module.scss';
import {useTranslation} from 'react-i18next';

interface CompaniesDashboardProps extends CompanyData {
  id: string;
  onChangeCompaniesSort?: Func<[string, string], void>;
  onActionButtonClick?: Func<[void], void>;
}

const CompaniesDashboard: React.FC<CompaniesDashboardProps> = ({
  id,
  companyLabel,
  companyCount,
  performingCompanyStats,
  widgets,
  onChangeCompaniesSort,
  isLoading,
  onActionButtonClick = () => null,
}) => {
  const {t} = useTranslation();
  const [sortValue, setSortValue] = useState('irr-desc');

  const handleChangeSort = (value: string) => {
    setSortValue(value);
    if (onChangeCompaniesSort) {
      const valueArr = value.split('-');
      onChangeCompaniesSort(valueArr[0], valueArr[1]);
    }
  };

  return (
    <section id={id} className={styles.wrapper}>
      <SectionHeader
        label={companyLabel}
        count={companyCount}
        buttonText={t('CompanyDashboard.SectionHeader.ShowAllCompanies')}
        buttonId={'dashboard-companies-show-all'}
        onClick={onActionButtonClick}
        childrenLeft={[
          <PerformingCompaniesStats
            performingCompanyStats={performingCompanyStats}
          />,
        ]}
        childrenRight={
          widgets.length > 1
            ? [
                <SelectDropdown
                  id={`${id}-select-dropdown-sorting`}
                  onChange={value => handleChangeSort(value as string)}
                  options={dashboardCompaniesSortingOptions}
                  value={sortValue}
                  label={t('Global.SortBy')}
                  fullContent
                />,
              ]
            : []
        }
        className={styles.sectionHeader}
      />
      <div className={styles.companiesSection}>
        {isLoading ? (
          <Fragment>
            {Array.from('1'.repeat(6)).map((_, i) => (
              <div key={i} className={styles.sectionWidget}>
                <CompanyWidgetSkeleton />
              </div>
            ))}
          </Fragment>
        ) : (
          widgets?.map((widget, idx: number) => {
            return (
              <div key={idx} className={styles.sectionWidget}>
                <CompanyWidget
                  {...widget}
                  sortingValue={sortValue.split('-')[0]}
                />
              </div>
            );
          })
        )}
      </div>
    </section>
  );
};

export default CompaniesDashboard;
