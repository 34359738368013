import React, {useMemo} from 'react';
import {
  DropdownWrapperAlignmentType,
  Func,
  MultiSelectOption,
  SelectOption,
  StyleVariant,
} from '../../../types';
import {useTranslation} from 'react-i18next';
import {formatWithLocale} from '../../../utils';
import classnames from 'classnames';
import {DropdownWrapper, MultiSelectDropdownOption} from '../index';
import styles from './MultiSelectDropdown.module.scss';

interface MultiSelectDropdownProps {
  id: string;
  label?: string;
  onChange: Func<[MultiSelectOption['value'], boolean], void>;
  options: MultiSelectOption[];
  value: MultiSelectOption['value'][];
  placeHolderKey: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  alignment?: DropdownWrapperAlignmentType;
  checkboxVariant?: StyleVariant;
}
const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  id,
  label,
  options,
  value,
  onChange,
  placeHolderKey,
  disabled = false,
  loading = false,
  className = '',
  alignment,
  checkboxVariant,
}) => {
  const {t} = useTranslation();

  const currentKey: string = useMemo(() => {
    let firstKey: string | number = '';
    if (!value.length) return placeHolderKey;
    const getCurrentKeyWithDate = (item: MultiSelectOption) =>
      item?.date && item?.format
        ? `${item.label} ${formatWithLocale(new Date(item.date), item.format)}`
        : item.label;

    options.forEach(item => {
      if (item.value === value[0]) {
        firstKey = getCurrentKeyWithDate(item);
      }
    });

    if (value.length === 1) return firstKey;

    return `${firstKey} +${value.length - 1}`;
  }, [options, value, placeHolderKey]);

  const handleClickOption = (
    value: SelectOption['value'],
    checked: boolean
  ) => {
    onChange(value, checked);
  };

  const getOptionLabel = (item: MultiSelectOption) => {
    const label = item.label as string;
    const translatedLabel: string = t(label);
    return item?.date && item?.format
      ? `${translatedLabel} ${formatWithLocale(
          new Date(item.date),
          item.format
        )}`
      : (translatedLabel as string);
  };

  return (
    <DropdownWrapper
      label={t(currentKey || placeHolderKey)}
      subLabel={t(label || '')}
      disabled={disabled}
      loading={loading}
      className={className}
      alignment={alignment}
      id={id}
    >
      <div id={id} data-test={id} className={styles.dropdown}>
        <div className={classnames(styles.dropdownInner)}>
          {options.map((option, idx) => (
            <MultiSelectDropdownOption
              key={idx}
              {...option}
              disabled={disabled}
              handleChange={handleClickOption}
              checkboxVariant={checkboxVariant}
              checked={value.includes(option.value)}
              label={getOptionLabel(option)}
            />
          ))}
        </div>
      </div>
    </DropdownWrapper>
  );
};

export default MultiSelectDropdown;
