import React from 'react';
import classnames from 'classnames';
import {ExposureChartType} from '../../../../../types/index';
import {ValueFormat} from '../../../../';
import styles from './Legend.module.scss';
import {useTranslation} from 'react-i18next';
import {formatToHyphenatedString} from '../../../../../utils/strings';

interface LegendProps extends Pick<ExposureChartType, 'data'> {
  unitLabel?: string;
  activeItem: number;
  colors: string[];
}

const Legend: React.FC<LegendProps> = ({
  unitLabel,
  activeItem,
  data,
  colors,
}) => {
  const {t} = useTranslation();
  return (
    <div className={classnames(styles.legend, unitLabel && styles.withHead)}>
      {unitLabel && (
        <div className={styles.legendItem}>
          <div className={classnames(styles.legendLabel, styles.head)} />
          <div className={classnames(styles.legendValue, styles.head)}>
            {unitLabel}
          </div>
        </div>
      )}
      {data?.map((item, index) => (
        <div
          className={classnames(
            styles.legendItem,
            activeItem === index && styles.active,
            activeItem > -1 && activeItem !== index && styles.inActive
          )}
          key={index}
        >
          <div className={styles.legendLabel}>
            <div
              className={styles.legendLabelInner}
              data-test={`legend-${formatToHyphenatedString(item.name)}-label`}
            >
              <div
                className={styles.legendColor}
                style={{
                  backgroundColor: `rgb(var(${colors[index % colors.length]}))`,
                }}
                data-test={`legend-${formatToHyphenatedString(
                  item.name
                )}-label`}
              />
              {t(item.name)}
            </div>
          </div>
          <div
            className={styles.legendValue}
            data-test={`legend-${formatToHyphenatedString(item.name)}-value`}
          >
            <ValueFormat value={item.value} format={item.format} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Legend;
