import React from 'react';
import {useAppSelector} from '../../../hooks/useReduxHooks';
import classnames from 'classnames';
import {Icon} from '../index';
import {OptionProps} from '../../../types';
import styles from './DropdownOption.module.scss';

const DropdownOption: React.FC<OptionProps> = ({
  id,
  value,
  label,
  isActive,
  iconName = 'checkbox',
  handleClickOption,
  withSeparator,
  className,
}) => {
  const {releaseEsgEnabled} = useAppSelector(state => state.featureFlags.data);

  // added disabled button and override class to match the design for release, TBR
  const applyOverride =
    (!releaseEsgEnabled && label === 'ESG') || label === 'Forecast';

  return (
    <div
      id={id}
      data-test={id}
      aria-selected={isActive}
      className={classnames(
        styles.option,
        isActive && styles.active,
        withSeparator && styles.withSeparator,
        applyOverride && styles.disableOption,
        className
      )}
      onClick={e => {
        e.preventDefault();
        handleClickOption(value);
      }}
      role="option"
    >
      <Icon className={styles.optionIcon} name={iconName} />
      {applyOverride ? (
        <div className={styles.overrideContent}>
          <div>{label}</div> <span>Coming soon</span>
        </div>
      ) : (
        label
      )}
    </div>
  );
};

export default DropdownOption;
