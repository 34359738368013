import {
  axisMetricsOptions,
  barChartFilterKpis,
  bubbleChartFilterKpis,
  treemapChartFilterKpis,
} from '../constants';
import {
  ActiveCompanyData,
  CompaniesChartConfigKpi,
  DataVisualisaitonChartType,
  FilterSectionComponentType,
  KpiFilterOption,
  MultiSelectOption,
  PCChartDataKeys,
  PCChartDataLoadingKey,
  QuarterlyDataAvailability,
} from '../types';

const getValueForAxis = (name: string) => {
  switch (name) {
    case 'yAxis':
    case 'yAxisRange':
      return 'valuation';
    case 'xAxis':
    case 'xAxisRange':
      return 'totalCost';
    case 'zAxis':
    case 'zAxisRange':
      return 'roi';
    default:
      return 'valuation';
  }
};
export const filterCompaniesBarChartData = (
  data: ActiveCompanyData[],
  filterData: KpiFilterOption[]
): ActiveCompanyData[] => {
  let result = [...data];
  filterData.forEach(filterItem => {
    if (
      filterItem.name === 'yAxis' ||
      filterItem.name === 'yAxisRange' ||
      filterItem.name === 'xAxis' ||
      filterItem.name === 'zAxis' ||
      filterItem.name === 'xAxisRange' ||
      filterItem.name === 'zAxisRange'
    )
      return;
    if (
      !filterItem.value ||
      !(filterItem.value as []).length ||
      (filterItem.value as string | string[]).includes('all')
    )
      return;
    if (filterItem.name === 'fundName') {
      result = result.filter(item => {
        return item.investmentDetails?.every(
          item =>
            !!item.fundName &&
            (filterItem.value as string[]).includes(item.fundName)
        );
      });
    }
    if (filterItem.name === 'currency') {
      result = result.filter(item => item.currency === filterItem.value);
    }
    if (filterItem.name === 'companyName') {
      result = result.filter(item =>
        (filterItem.value as string[]).includes(item.companyName)
      );
    }
    if (filterItem.name === 'sector') {
      result = result.filter(item =>
        (filterItem.value as string[]).includes(item.sector || 'Unspecified')
      );
    }
    if (filterItem.name === 'region') {
      result = result.filter(item =>
        (filterItem.value as string[]).includes(item.region || 'Unspecified')
      );
    }
  });
  return result;
};

export const generateChartFilterConfig = (
  data: ActiveCompanyData[],
  configKpis: CompaniesChartConfigKpi[],
  quarterlyData: QuarterlyDataAvailability
): KpiFilterOption[] => {
  const currencyFilter = {
    label: 'Currency',
    type: FilterSectionComponentType.SelectDropdown,
    name: 'currency',
    value: getMaxOccurrenceValue(data.map(x => x.currency)),
    options: getKpiOptions(
      data,
      'currency',
      FilterSectionComponentType.SelectDropdown
    ),
  };
  const dataFilteredByCurrency = data.filter(
    item => item.currency === currencyFilter.value
  );
  return configKpis.reduce((acc: KpiFilterOption[], item) => {
    if (item.name === 'currency') {
      acc = [...acc, currencyFilter];
      return acc;
    }
    if (
      item.name === 'yAxis' ||
      item.name === 'xAxis' ||
      item.name === 'zAxis'
    ) {
      acc = [
        ...acc,
        {
          label: item.label,
          type: FilterSectionComponentType.SelectDropdown,
          name: item.name,
          value: getValueForAxis(item.name),
          options: axisMetricsOptions,
        },
      ];
      return acc;
    }
    if (
      item.name === 'yAxisRange' ||
      item.name === 'xAxisRange' ||
      item.name === 'zAxisRange'
    ) {
      acc = [
        ...acc,
        {
          label: '',
          type: FilterSectionComponentType.MultiRangeSlider,
          name: item.name,
          value: getMinMaxKpiRange(data, getValueForAxis(item.name)),
          options: getMinMaxKpiRange(data, getValueForAxis(item.name)),
        },
      ];
      return acc;
    }
    if (item.name === 'asAt') {
      acc = [
        ...acc,
        {
          label: item.label,
          type: FilterSectionComponentType.AsAt,
          name: item.name,
          value: quarterlyData.date,
          options: [quarterlyData.startDate, quarterlyData.endDate],
        },
      ];
      return acc;
    }
    acc = [
      ...acc,
      {
        label: item.label,
        type: FilterSectionComponentType.MultiSelectDropdown,
        name: item.name,
        value: [],
        placeHolderKey: item.placeHolderKey,
        options: getKpiOptions(
          dataFilteredByCurrency,
          item.name,
          FilterSectionComponentType.MultiSelectDropdown
        ) as MultiSelectOption[],
      },
    ];
    return acc;
  }, []);
};

const getKpiOptions = (
  data: ActiveCompanyData[],
  kpi: string,
  type: FilterSectionComponentType
) => {
  const options = data
    .map(dataItem => {
      //
      if (kpi === 'fundName') {
        return (
          dataItem.investmentDetails?.map(invItem => invItem.fundName) ?? null
        );
      }
      if (
        kpi === 'companyName' ||
        kpi === 'currency' ||
        kpi === 'sector' ||
        kpi === 'region'
      ) {
        return dataItem[kpi as keyof ActiveCompanyData] as string | number;
      }
      return [];
    })
    .flat()
    .map(item => (item === null ? 'Unspecified' : item))
    .filter((v, i, arr) => arr.indexOf(v) === i); // remove duplicate entries

  const selectOptions = options
    .map((arrOption, i) => ({
      id: i.toString(),
      label: arrOption,
      value: arrOption,
    }))
    .sort((a, b) => a.label.toString().localeCompare(b.label.toString()));

  return [...selectOptions];
};

export const getMinMaxKpiRange = (data: any[], kpi: string): number[] => {
  const arr: number[] = data.map(el => el[kpi]?.value || 0);
  const min = arr.reduce((acc, item) => (item < acc ? item : acc), arr[0]);
  const max = arr.reduce((acc, item) => (item > acc ? item : acc), arr[0]);
  return [min, max];
};

const getMaxOccurrenceValue = (arr: string[]): string => {
  return Array.from(new Set(arr)).reduce((acc, curr) =>
    arr.filter(el => el === curr).length > arr.filter(el => el === acc).length
      ? curr
      : acc
  );
};

export const handleChangeFilterCompaniesFilter = (
  name: string,
  value: string | number | string[] | number[],
  filterData: KpiFilterOption[],
  chartData: ActiveCompanyData[]
): KpiFilterOption[] => {
  const newFilteredData = filterData.map(item => {
    if (
      (name === 'yAxis' && item.name === 'yAxisRange') ||
      (name === 'xAxis' && item.name === 'xAxisRange') ||
      (name === 'zAxis' && item.name === 'zAxisRange')
    ) {
      return {
        ...item,
        options: getMinMaxKpiRange(chartData || [], value as string),
        value: getMinMaxKpiRange(chartData || [], value as string),
      };
    }
    if (
      item.type === FilterSectionComponentType.MultiSelectDropdown &&
      item.name === name
    ) {
      const originalValue = item.value as string[];
      const newValue = originalValue.includes(value as string)
        ? [...originalValue.filter(item => item !== value)]
        : [...originalValue, value as string];
      return {
        ...item,
        value: newValue,
      };
    }
    if (item.name === name) {
      return {
        ...item,
        value: value,
      };
    }
    return item;
  });

  const newFilterDataWithOptions = newFilteredData.map((item, _, array) => {
    if (
      item.name === 'yAxis' ||
      item.name === 'xAxis' ||
      item.name === 'zAxis' ||
      item.name === 'yAxisRange' ||
      item.name === 'xAxisRange' ||
      item.name === 'zAxisRange' ||
      item.name === 'asAt'
    )
      return item;

    const filteredData = filterCompaniesBarChartData(
      chartData,
      array.filter(item2 => item.name !== item2.name)
    );
    const options = getKpiOptions(filteredData, item.name, item.type);
    const value = Array.isArray(item.value)
      ? (item.value as string[]).filter(valueItem =>
          options.some(option => option.value === valueItem)
        )
      : item.value;
    return {
      ...item,
      value,
      options,
    };
  });

  return newFilterDataWithOptions;
};

export const getDataVisualisationChartDataKeys = (
  item: DataVisualisaitonChartType
): PCChartDataKeys => {
  return item === DataVisualisaitonChartType.BarChart
    ? {
        chartKey: 'barChartData',
        filterKey: 'barChartFilter',
        filterKpis: barChartFilterKpis,
      }
    : item === DataVisualisaitonChartType.BubbleChart
    ? {
        chartKey: 'bubbleChartData',
        filterKey: 'bubbleChartFilter',
        filterKpis: bubbleChartFilterKpis,
      }
    : {
        chartKey: 'treemapData',
        filterKey: 'treemapFilter',
        filterKpis: treemapChartFilterKpis,
      };
};

export const getDataVisualisationChartDataRequestPendingKey = (
  item: DataVisualisaitonChartType
): PCChartDataLoadingKey => {
  return item === DataVisualisaitonChartType.BarChart
    ? 'barChartDataRequestPending'
    : item === DataVisualisaitonChartType.BubbleChart
    ? 'bubbleChartDataRequestPending'
    : 'treemapDataRequestPending';
};
