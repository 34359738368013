import {
  call,
  put,
  all,
  takeLatest,
  select,
  takeEvery,
} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {
  getActiveCompaniesQuarterlyData,
  getAllCompanies,
} from '../../api/getAllCompanies';
import {errorHandlerAction} from '../../actions';
import {acquireAuthResult, msalInstance} from '../../';
import {
  ClientActiveCompaniesResponse,
  Companies,
  DataVisualisaitonChartType,
  InvestmentPerformanceData,
} from '../../types';
import {
  getCompanyPerformanceDataAction,
  setCompaniesList,
  setCompanyPerformanceData,
  setTilesData,
  clearCompaniesList,
} from '../../reducers/companiesSlice';

import {getInvestmentPerformanceData} from '../../api/getFundDetailsPortfolio';
import {
  getActiveCompaniesQuarterlyDataAction,
  setChartDataRequestPending,
  setPortfolioCompaniesChartData,
} from '../../reducers/portfolioCompaniesChartDataSlice';
import {getDataVisualisationChartDataRequestPendingKey} from '../../utils';

export const getAllCompaniesSaga = function* getAllCompaniesSaga({
  payload,
}: any): any {
  const isUserProfileUpdatePending = yield select(
    (state: RootState) => state.userProfile.isLoading
  );
  if (!isUserProfileUpdatePending) {
    yield put(clearCompaniesList());
  }
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: Companies = yield call(getAllCompanies, accessToken);
    yield put(setCompaniesList(response));
    yield put(
      setTilesData({
        active: response.active.data
          .flatMap(item => item.investmentDetails ?? item)
          .sort((a, b) => b.irr?.value! - a.irr?.value!),
        exited: response.exited.data
          .flatMap(item => item.investmentDetails ?? item)
          .sort((a, b) => b.irr?.value! - a.irr?.value!),
        writtenOff: response.writtenOff.data
          .flatMap(item => item.investmentDetails ?? item)
          .sort((a, b) => b.realisedLoss?.value! - a.realisedLoss?.value!),
      })
    );

    const {barChartData, bubbleChartData, treemapData} = yield select(
      (state: RootState) => state.companiesChartData.data
    );

    const shouldResetChartData = payload?.shouldResetChartData;

    const chartTypes = [
      ...(shouldResetChartData || !barChartData
        ? [DataVisualisaitonChartType.BarChart]
        : []),
      ...(shouldResetChartData || !bubbleChartData
        ? [DataVisualisaitonChartType.BubbleChart]
        : []),
      ...(shouldResetChartData || !treemapData
        ? [DataVisualisaitonChartType.TreemapChart]
        : []),
    ];
    yield put(
      setPortfolioCompaniesChartData({
        companies: response.active.data,
        quarterlyData: response.quarterlyData,
        chartTypes,
      })
    );
  } catch (err) {
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const updateCompanyPerformanceDataSaga =
  function* updateCompanyPerformanceDataSaga({
    payload,
  }: PayloadAction<{
    companyId: string;
    companyType: 'active' | 'exited';
    currency: string;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: Array<InvestmentPerformanceData> = yield call(
        getInvestmentPerformanceData,
        accessToken,
        payload.companyId,
        'portfolio-company-performance',
        'true',
        '',
        payload.currency
      );
      yield put(
        setCompanyPerformanceData({
          performanceData: response,
          companyId: payload.companyId,
          companyType: payload.companyType,
          currency: payload.currency,
        })
      );
    } catch (err) {
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  };

export const getActiveCompaniesQuarterlyDataSaga =
  function* getActiveCompaniesQuarterlyDataSaga({
    payload,
  }: PayloadAction<{
    name: string;
    value: string;
    chartType: DataVisualisaitonChartType;
  }>) {
    const requestPendingKey = getDataVisualisationChartDataRequestPendingKey(
      payload.chartType
    );

    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(
        setChartDataRequestPending({requestPendingKey, isLoading: true})
      );
      const response: ClientActiveCompaniesResponse = yield call(
        getActiveCompaniesQuarterlyData,
        accessToken,
        payload.value
      );

      yield put(
        setPortfolioCompaniesChartData({
          companies: response.companies,
          quarterlyData: response.quarterlyData,
          chartTypes: [payload.chartType],
        })
      );
    } catch (err) {
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    } finally {
      yield put(
        setChartDataRequestPending({requestPendingKey, isLoading: false})
      );
    }
  };

export const allCompaniesSagaWatcher = function* allCompaniesSagaWatcher() {
  yield all([
    takeLatest(
      getCompanyPerformanceDataAction.type,
      updateCompanyPerformanceDataSaga
    ),
    takeEvery(
      getActiveCompaniesQuarterlyDataAction.type,
      getActiveCompaniesQuarterlyDataSaga
    ),
  ]);
};
