import React, {useState} from 'react';
import {GenericTable} from '../../global/table';
import {
  NearCastingScenarioCompany,
  NearCastingScenarioCompanyFormData,
  ScenarioValuationCompanyRow,
} from '../../../types';
import genScenarioValuationTableColumns from '../../global/table/scenarios/valuation/ValuationCompanies';
import {useAppSelector} from '../../../hooks/useReduxHooks';
import ValuationTableExpandedRow from '../ValuationTableExpandedRow/ExpandedValuationRow';
import CompanyForm from '../CompanyForm/CompanyForm';
import styles from './ScenarioValuation.module.scss';
import {useTranslation} from 'react-i18next';

export enum RowExpandType {
  Transactions = 'transactions',
  Edit = 'edit',
}

const columns = [
  {
    label: 'Portfolio Company',
    columnId: 'name',
  },
  {
    label: 'Currency',
    columnId: 'currency',
  },
  {
    label: 'Reported Cost',
    columnId: 'reportedSignOffCost',
  },
  {
    label: 'Reported Valuation',
    columnId: 'reportedSignOffValuation',
  },
  {
    label: 'Predict Cost',
    columnId: 'nearCastCost',
  },
  {
    label: 'Predict Valuation',
    columnId: 'valuation',
  },
  {
    label: 'Comment',
    columnId: 'comment',
  },
];

interface ScenarioValuationProps {
  fundCurrency: string;
  tableData: NearCastingScenarioCompany[];
  handleCompanyFieldEdit: (
    data: NearCastingScenarioCompany,
    field: string,
    value: string,
    tableIndex: number
  ) => void;
  handleCompanyEdit: (
    data: NearCastingScenarioCompanyFormData,
    tableIndex: number
  ) => void;
  handleDeleteCompany: (companyId: string) => void;
}

const ScenarioValuation = ({
  fundCurrency,
  tableData,
  handleCompanyFieldEdit,
  handleCompanyEdit,
  handleDeleteCompany,
}: ScenarioValuationProps) => {
  const {t} = useTranslation();
  const [expandType, setExpandType] = useState(RowExpandType.Transactions);
  const {data} = useAppSelector(state => state.scenarioDetails.report);
  const fundColor = data?.fundColor || '';

  const handleValuationTableFieldChanges = (
    field: string,
    tableIndex: number,
    value: string
  ) => {
    handleCompanyFieldEdit(tableData[tableIndex], field, value, tableIndex);
  };

  const handleValuationTableDeleteCompany = (tableIndex: number) => {
    handleDeleteCompany(tableData[tableIndex].id);
  };

  const rowData = tableData.map(elem => {
    return {
      name: elem.name,
      currency: elem.currency,
      reportedCost: elem.reportedCost,
      reportedSignOffCost: elem.reportedSignOffCost,
      reportedValuation: elem.reportedValuation,
      reportedSignOffValuation: elem.reportedSignOffValuation,
      nearCastCost: elem.cost,
      valuation: elem.valuation,
      comment: elem.comment || '',
      isCasting: !elem?.portfolioCompany?.id,
    };
  });

  const isEmpty = !rowData.length;

  return (
    <div>
      {isEmpty ? (
        <div className={styles.emptyState}>
          {t('ScenarioDetailsValuation.ScenarioValuation.EmptyState')}
        </div>
      ) : (
        <GenericTable<ScenarioValuationCompanyRow>
          data={rowData}
          className={styles.table}
          columns={genScenarioValuationTableColumns(
            columns,
            fundColor,
            fundCurrency,
            setExpandType,
            handleValuationTableDeleteCompany
          )}
          expandedView={({row}) => {
            const company = tableData[row.index];
            return expandType === RowExpandType.Transactions ? (
              <ValuationTableExpandedRow
                company={company}
                fundCurrency={fundCurrency}
              />
            ) : (
              <div className={styles.editFormWrapper}>
                <CompanyForm
                  data={company}
                  onCancel={row.toggleExpanded}
                  onSubmit={data => handleCompanyEdit(data, row.index)}
                />
              </div>
            );
          }}
          meta={{
            updateData: (rowIndex, columnId, value) => {
              handleValuationTableFieldChanges(
                columnId,
                rowIndex,
                value as string
              );
            },
            getRowStyles: row => {
              return row.original.isCasting
                ? {
                    background: `var(--nearcasting-gradient)`,
                  }
                : {};
            },
          }}
          getRowCanExpand={() => true}
        />
      )}
    </div>
  );
};

export default ScenarioValuation;
