import React, {Fragment, useCallback, useState} from 'react';
import styles from './FilterSection.module.scss';
import {
  FilterSectionComponentType,
  Func,
  KpiFilterOption,
  MultiSelectOption,
  SelectOption,
} from '../../../types';
import {AsAtController, MultiSelectDropdown, SelectDropdown} from '../index';
import classnames from 'classnames';

interface FilterSectionProps {
  config: KpiFilterOption[];
  isDisabled: boolean;
  handleChange: Func<
    [{name: string; value: string | number | string[] | number[]}],
    void
  >;
}
const FilterSection: React.FC<FilterSectionProps> = ({
  config,
  isDisabled,
  handleChange,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const getComponent = useCallback(
    (item: KpiFilterOption, i: number) => {
      switch (item.type) {
        case FilterSectionComponentType.SelectDropdown:
          return (
            <div className={styles.item}>
              <div className={styles.label}>{item.label}</div>
              <SelectDropdown
                {...item}
                options={item.options as SelectOption[]}
                id={`${item.name}-${i}`}
                label={''}
                value={item.value as string}
                className={styles.select}
                onChange={value => handleChange({name: item.name, value})}
                disabled={isDisabled}
              />
            </div>
          );
        case FilterSectionComponentType.MultiSelectDropdown:
          return (
            <div className={styles.item}>
              <div className={styles.label}>{item.label}</div>
              <MultiSelectDropdown
                {...item}
                options={item.options as MultiSelectOption[]}
                id={`${item.name}-${i}`}
                label={''}
                className={styles.select}
                placeHolderKey={item.placeHolderKey ?? ''}
                value={item.value as string[]}
                onChange={value => handleChange({name: item.name, value})}
                disabled={isDisabled}
              />
            </div>
          );
        case FilterSectionComponentType.AsAt:
          return (
            <div className={styles.item}>
              <div className={styles.label}>{item.label}</div>
              <AsAtController
                label={''}
                startDate={item.options[0] as string}
                endDate={item.options[1] as string}
                onChange={value => handleChange({name: item.name, value})}
                value={item.value as string}
                className={styles.select}
                disabled={isDisabled}
              />
            </div>
          );
        default:
          return null;
      }
    },
    [isDisabled]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        {config.slice(0, 3).map((item, index) => (
          <Fragment key={index}>{getComponent(item, index)}</Fragment>
        ))}
      </div>
      {config.length > 3 && (
        <Fragment>
          <div
            className={classnames(styles.inner, collapsed && styles.collapsed)}
          >
            {config.slice(3).map((item, index) => (
              <Fragment key={index}>{getComponent(item, index)}</Fragment>
            ))}
          </div>
          <button
            onClick={() => setCollapsed(prev => !prev)}
            className={styles.button}
          >
            {!collapsed ? 'Hide advanced filters' : 'Show advanced filters'}
          </button>
        </Fragment>
      )}
    </div>
  );
};

export default FilterSection;
