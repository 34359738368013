import React, {useMemo, useState} from 'react';
import {Func, TreemapChartData} from '../../../types';
import {ResponsiveContainer, Treemap} from 'recharts';
import {createGradients} from '../../../utils';
import styles from './TreemapChartWidget.module.scss';
import TreemapChartWidgetNode from './components/TreemapChartWidgetNode';
import classnames from 'classnames';

interface TreemapChartWidgetProps<T> {
  timeSeries: TreemapChartData<T>;
  tileScaleLabel?: string;
  colorLabel?: string;
  className?: string;
  onClickNode: Func<[T], void>;
}
const TreemapChartWidget = <T extends object>({
  timeSeries,
  colorLabel,
  tileScaleLabel,
  className,
  onClickNode,
}: TreemapChartWidgetProps<T>) => {
  const [animationEnded, setAnimationEnded] = useState(false);
  const [hoveredId, setHoveredId] = useState('');

  const timeSeriesWithColors = useMemo(() => {
    const generatedColors = createGradients(
      '#1E144A',
      '#18C1FF',
      timeSeries.children.length
    );

    return {
      ...timeSeries,
      children: timeSeries.children
        .sort((a, b) => (b.colorValue || 0) - (a.colorValue || 0))
        .map((item, index) => ({
          ...item,
          color: generatedColors[index],
        }))
        .sort((a, b) => (b.tileScaleValue || 0) - (a.tileScaleValue || 0)),
    };
  }, [timeSeries]);

  return (
    <div className={classnames(styles.wrapper, className)}>
      <ResponsiveContainer
        width="100%"
        aspect={4 / 2}
        id="treemap-chart-widget"
      >
        <Treemap
          data={[timeSeriesWithColors]}
          dataKey="tileScaleValue"
          aspectRatio={1 / 2}
          stroke={'rgb(var(--colors-black))'}
          onAnimationEnd={() => setAnimationEnded(true)}
          content={
            <TreemapChartWidgetNode
              tileScaleLabel={tileScaleLabel}
              colorLabel={colorLabel}
              onClickNode={onClickNode}
              canBeHovered={animationEnded}
              hoveredId={hoveredId}
              onHover={setHoveredId}
            />
          }
        />
      </ResponsiveContainer>
    </div>
  );
};

export default TreemapChartWidget;
