import React from 'react';
import styles from './BarChartLabel.module.scss';

const BarChartLabel = (props: any) => {
  const {x, y, height} = props;
  return (
    <foreignObject
      transform={`translate(${x},${y}) `}
      width={props.width}
      height={props.height}
      className={styles.wrapper}
    >
      <div
        style={{
          width: height - 10,
        }}
        className={styles.labelWrapper}
      >
        {props.value}
      </div>
    </foreignObject>
  );
};

export default BarChartLabel;
