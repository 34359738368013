import axios from 'axios';
import config from '../config';
import {ClientActiveCompaniesResponse, Companies} from '../types/index';

export const getAllCompanies = async (token: string, page?: number) => {
  const {data} = await axios.get<Companies[]>(
    `${config.APP_URI}/portfolio-company-lists`,
    {
      params: {
        page: page || 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const getActiveCompaniesQuarterlyData = async (
  token: string,
  date: string
) => {
  const {data} = await axios.get<ClientActiveCompaniesResponse[]>(
    `${config.APP_URI}/portfolio-company-active-list?date=${date}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};
