import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Func} from '../../../types';
import styles from './MultiRangeSlider.module.scss';
import classnames from 'classnames';
import {ValueFormat} from '../index';

interface MultiRangeSliderProps {
  min: number;
  max: number;
  minValue: number;
  maxValue: number;
  step: number;
  format?: string;
  onChange: Func<[{min: number; max: number}], void>;
}
const MultiRangeSlider: React.FC<MultiRangeSliderProps> = ({
  min,
  max,
  minValue,
  maxValue,
  step,
  onChange,
  format,
}) => {
  const [state, setState] = useState({minValue, maxValue});
  const range = useRef<HTMLDivElement>(null);
  const thumbLeft = useRef<HTMLDivElement>(null);
  const thumbRight = useRef<HTMLDivElement>(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => ((value - min) / (max - min)) * 100,
    [min, max]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'rangeMin') {
      const value = Math.min(Number(event.target.value), maxValue - step);
      setState(prev => ({...prev, minValue: value}));
    }
    if (event.target.name === 'rangeMax') {
      const value = Math.max(Number(event.target.value), minValue + step);
      setState(prev => ({...prev, maxValue: value}));
    }
  };

  const handleMouseUp = () => {
    onChange({min: state.minValue, max: state.maxValue});
  };

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const {minValue, maxValue} = state;
    const minPercent = getPercent(minValue);
    const maxPercent = getPercent(maxValue);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
    if (thumbLeft.current) {
      thumbLeft.current.style.left = `${minPercent}%`;
    }
  }, [state.minValue]);

  useEffect(() => {
    setState(prev => ({
      ...prev,
      minValue: minValue !== prev.minValue ? minValue : prev.minValue,
    }));
  }, [minValue]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const {minValue, maxValue} = state;
    const minPercent = getPercent(minValue);
    const maxPercent = getPercent(maxValue);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
    if (thumbRight.current) {
      thumbRight.current.style.left = `${maxPercent}%`;
    }
  }, [state.maxValue]);

  useEffect(() => {
    setState(prev => ({
      ...prev,
      maxValue: maxValue !== prev.maxValue ? maxValue : prev.maxValue,
    }));
  }, [maxValue]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftValue}>
        <ValueFormat value={state.minValue} format={format} />
      </div>
      <div className={styles.slider}>
        <input
          step={step}
          type="range"
          name="rangeMin"
          min={min}
          max={max}
          value={state.minValue}
          onChange={handleChange}
          onMouseUp={handleMouseUp}
          className={classnames(styles.thumb, styles.thumb__left)}
          style={{zIndex: (state.minValue > max - 100 && '5') || ''}}
        />
        <input
          step={step}
          type="range"
          name="rangeMax"
          min={min}
          max={max}
          value={state.maxValue}
          onChange={handleChange}
          onMouseUp={handleMouseUp}
          className={classnames(styles.thumb, styles.thumb__right)}
        />
        <div className={styles.track} />
        <div ref={range} className={styles.range} />
      </div>
      <div className={styles.rightValue}>
        <ValueFormat value={state.maxValue} format={format} />
      </div>
    </div>
  );
};

export default MultiRangeSlider;
