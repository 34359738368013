import {all, call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {matchRoutes} from 'react-router-config';
import {replace} from 'connected-react-router';
import {toast} from 'react-toastify';
import i18n from '../../i18n';
import {acquireAuthResult, logoutRedirectRequest, msalInstance} from '../..';
import {END_SIDE_EFFECTS_RUNNING} from '../connectedRouterSagas';
import routePaths from '../../routePaths';
import routes from '../../routes';
import {RootState} from '../../store';
import {UserProfileData, UserRole} from '../../types';
import {getUserDataVisibility, getUserProfile} from '../../api/getUserProfile';
import {
  getUserProfileServiceData,
  setUserAnalyticsDataAction,
  setUserProfileServiceData,
  setUserProfileServiceLoading,
  setUserProfileServiceShowSignedOffData,
  updateUserDataVisibilityAction,
} from '../../reducers/userProfileServiceSlice';
import {setCurrentPage} from '../../reducers/pageSlice';
import {setDocumentLang} from '../../utils/locale';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  callToastError,
  callToastInfo,
  callToastSuccess,
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESSFUL_TOAST_OPTIONS,
} from '../../utils';
import {getClientDashboardSaga} from '../dashboard/getClientDashboardSaga';
import {getAllFundsSaga} from '../funds/getAllFundsSaga';
import {fundDetailsOverviewSaga} from '../fund-details/fundDetailsOverviewSaga';
import {getAllCompaniesSaga} from '../companies/getAllCompaniesSaga';
import {fundDetailsOperationalSaga} from '../fund-details/fundDetailsOperationalSaga';
import {fundDetailsStructureSaga} from '../fund-details/fundDetailsStructureSaga';
import {fundDetailsPortfolioSaga} from '../fund-details/fundDetailsPortfolioSaga';
import {fundDetailsPortfolioTransactionsSaga} from '../fund-details/fundDetailsPortfolioTransactionsSaga';
import {companyDetailsInvestmentOverviewSaga} from '../company-details/companyDetailsInvestmentOverviewSaga';
import {companyDetailsCompanySaga} from '../company-details/companyDetailsCompanySaga';

export const userProfileServiceSaga = function* userProfileServiceSaga({
  payload,
}: any): any {
  if (payload.location.pathname === routePaths.NOT_FOUND) {
    return;
  }

  const accountInfo = msalInstance.getAllAccounts();
  if (accountInfo.length > 0) {
    const data: UserProfileData | null = yield select(
      (state: RootState) => state.userProfile.data
    );
    if (!data) {
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield put(getUserProfileServiceData());
        const response: UserProfileData = yield call(
          getUserProfile,
          accessToken
        );

        // @TODO At this place we'll set the locale for the localisation library when is integrated
        // Set application document language
        if (response?.locale) {
          setDocumentLang(response.locale);
        }

        yield put(setUserProfileServiceData(response));
        yield put(setUserAnalyticsDataAction(response));

        return yield call(
          userPageAccessSaga,
          payload?.location?.pathname,
          response
        );
      } catch (err) {
        console.warn(err);
        console.warn('get user profile error');
        yield call(logoutRedirectRequest, msalInstance);
        return END_SIDE_EFFECTS_RUNNING;
      }
    } else {
      return yield call(userPageAccessSaga, payload?.location?.pathname, data);
    }
  }
};

//** to control feature flag based page access */
//** client admin/user all pages access except Reporting; company admin only Reporting page access */
export const userPageAccessSaga = function* userPageAccessSaga(
  pathname: string,
  data: UserProfileData
): any {
  try {
    const matchedRoutes = matchRoutes(routes[0].routes as any, pathname).filter(
      item => item.match.isExact
    );
    const page = matchedRoutes[0]?.route?.page;

    const {releaseDataCollectionEnabled, releaseDocumentRepositoryEnabled} =
      yield select((state: RootState) => state.featureFlags.data);

    // Data Collection route access disabled
    if (
      [
        'Data Collection Administration',
        'Admin Template Configuration',
        'Admin Workflow Configuration',
        'Admin Template Configuration Details',
        'Admin Portfolio Company Users',
        'Data Collection',
        'Data Collection Details',
        'Company Admin Reporting',
        'Portfolio Monitoring',
      ].includes(page) &&
      !releaseDataCollectionEnabled
    ) {
      yield put(replace(routePaths.NOT_FOUND));
      return END_SIDE_EFFECTS_RUNNING;
    }

    // DOCUMENT REPOSITORY route access disabled
    if (
      ['Documents', 'Document Explorer', 'Transaction Pivot'].includes(page) &&
      !releaseDocumentRepositoryEnabled
    ) {
      yield put(replace(routePaths.NOT_FOUND));
      return END_SIDE_EFFECTS_RUNNING;
    }
    //TODO: Remove ^^^

    if (data.pages.includes(page) || page === 'Unavailable Page') {
      yield put(setCurrentPage({name: page}));
      return;
    }

    yield delay(0);
    console.warn('page access denied, check user role page access');
    yield put(
      replace(
        data.userRole.includes(UserRole.CompanyAdmin)
          ? routePaths.REPORTING
          : routePaths.HOME
      )
    );
    return END_SIDE_EFFECTS_RUNNING;
  } catch (err) {
    console.warn(err);
  }
};

export const updateUserDataVisibilitySaga =
  function* updateUserDataVisibilitySaga({
    payload,
  }: PayloadAction<{value: boolean; userId: string}>) {
    try {
      yield put(setUserProfileServiceShowSignedOffData(payload.value));
      yield put(setUserProfileServiceLoading(true));
      // ToDo: add trans key if toast approved here
      yield call(callToastInfo, i18n.t('Data reloading...'), {
        ...DEFAULT_SUCCESSFUL_TOAST_OPTIONS,
        autoClose: false,
        theme: 'colored',
      });
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: {showSignedOffData: boolean} = yield call(
        getUserDataVisibility,
        accessToken,
        payload.userId,
        payload.value
      );
      const currentState: boolean = yield select(
        (state: RootState) => state.userProfile.data?.isSignedOffData
      );
      if (response.showSignedOffData !== currentState) {
        yield put(
          setUserProfileServiceShowSignedOffData(response.showSignedOffData)
        );
      }
      yield call(pageDataReloadHelperSaga);
      yield call(toast.dismiss);
      // ToDo: add trans key if toast approved here
      yield call(
        callToastSuccess,
        i18n.t('Data reloading finished'),
        DEFAULT_SUCCESSFUL_TOAST_OPTIONS
      );
    } catch (err: any) {
      console.warn(err);
      yield put(setUserProfileServiceShowSignedOffData(!payload.value));
      yield call(toast.dismiss);
      // ToDo: add trans key if toast approved here
      yield call(
        callToastError,
        err.response?.data?.detail || i18n.t('Data reloading error'),
        DEFAULT_ERROR_TOAST_OPTIONS
      );
    } finally {
      yield put(setUserProfileServiceLoading(false));
    }
  };

export const pageDataReloadHelperSaga =
  function* pageDataReloadHelperSaga(): any {
    try {
      const currentPage: string = yield select(
        (state: RootState) => state.page.name
      );

      const pathname: string = yield select(
        (state: RootState) => state.router.location.pathname
      );
      const matchedRoutes = matchRoutes(
        routes[0].routes as any,
        pathname
      ).filter(item => item.match.isExact);

      if (currentPage === 'Dashboard') {
        return yield call(getClientDashboardSaga);
      }
      if (currentPage === 'Funds') {
        return yield call(getAllFundsSaga);
      }
      if (currentPage === 'Portfolio Companies') {
        return yield call(getAllCompaniesSaga, {
          payload: {shouldResetChartData: true},
        });
      }
      if (currentPage === 'Fund Overview') {
        return yield call(fundDetailsOverviewSaga, {payload: matchedRoutes[0]});
      }
      if (currentPage === 'Fund Operational') {
        return yield call(fundDetailsOperationalSaga, {
          payload: matchedRoutes[0],
        });
      }
      if (currentPage === 'Fund Structure') {
        return yield call(fundDetailsStructureSaga, {
          payload: matchedRoutes[0],
        });
      }
      if (currentPage === 'Fund Portfolio') {
        return yield call(fundDetailsPortfolioSaga, {
          payload: matchedRoutes[0],
        });
      }
      if (currentPage === 'Fund Portfolio Transactions') {
        return yield call(fundDetailsPortfolioTransactionsSaga, {
          payload: matchedRoutes[0],
        });
      }
      if (currentPage === 'Portfolio Company Investment Overview') {
        return yield call(companyDetailsInvestmentOverviewSaga, {
          payload: matchedRoutes[0],
        });
      }
      if (currentPage === 'Portfolio Company') {
        return yield call(companyDetailsCompanySaga, {
          payload: matchedRoutes[0],
        });
      }
    } catch (err) {
      console.warn(err);
    }
  };

export const userProfileServiceSagaWatcher =
  function* userProfileServiceSagaWatcher() {
    yield all([
      takeEvery(
        updateUserDataVisibilityAction.type,
        updateUserDataVisibilitySaga
      ),
    ]);
  };
