import React, {useMemo} from 'react';
import {ActiveCompanyData} from '../../../../../types/index';
import styles from './CompanyDelta.module.scss';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';
import {Icon} from '../../../../global';
import {useTranslation} from 'react-i18next';

interface CompanyDeltaProps {
  sortValue: string;
  data: Pick<ActiveCompanyData, 'irr' | 'valuation' | 'totalCost'>;
}

const CompanyDelta: React.FC<CompanyDeltaProps> = ({data, sortValue}) => {
  const {t} = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {showTooltip, hideTooltip} = useTooltip();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMouseEnter = (e: React.MouseEvent) => {
    showTooltip({
      target: e.currentTarget as HTMLElement,
      content: t('Global.CompareQuarters'),
      position: Position.Top,
    });
  };
  const value = useMemo(() => {
    if (sortValue === 'total_cost' || sortValue === 'totalCost') {
      return {
        value: data.totalCost.deltaIndicator?.value,
        label: t('Global.TotalCost'),
        isUp: data.totalCost.deltaIndicator?.isUp,
      };
    }
    if (sortValue === 'irr') {
      return {
        value: data.irr?.deltaIndicator?.value,
        label: t('IRR'),
        isUp: data.irr?.deltaIndicator?.isUp,
      };
    }
    if (sortValue === 'valuation') {
      return {
        value: data.valuation.deltaIndicator?.value,
        label: t('Global.Valuation'),
        isUp: data.valuation.deltaIndicator?.isUp,
      };
    }
    return null;
  }, [sortValue, data]);

  if (!value) {
    return null;
  }

  return (
    <div
      className={styles.delta}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={hideTooltip}
    >
      {value.isUp ? (
        <Icon className={styles.icon} name={'stats-up'} />
      ) : (
        <Icon className={styles.icon} name={'stats-down'} />
      )}
      <div>
        {value.isUp && '+'}
        {value.value}
      </div>
      <div className={styles.valueLabel}>{value.label}</div>
    </div>
  );
};

export default CompanyDelta;
