import React from 'react';
import classnames from 'classnames';
import {
  HeaderMapping,
  FlexHAlign,
  ScenarioValuationCompanyRow,
} from '../../../../../types';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../../cells/th/Th';
import Td from '../../cells/td/Td';
import ExpanderCell from '../../cells/expander-cell/ExpanderCell';
import {valuationNumberRegex} from '../../../../../constants/regExps';
import styles from './index.module.scss';
import {valueFormat} from '../../../../../utils/value-format';
import NameCell from '../../cells/name-cell/NameCell';
import EditableCell from '../../cells/editable-cell/EditableCell';
import CommentCell from '../../cells/comment-cell/CommentCell';
import DotsMenuCell from '../../cells/dots-menu-cell/DotsMenuCell';
import {RowExpandType} from '../../../../scenario-valuation/ScenarioValuation/ScenarioValuation';

const genScenarioValuationTableColumns = (
  headerMapping: HeaderMapping[],
  color: string,
  fundCurrency: string,
  setExpandType: (expandType: RowExpandType) => void,
  handleDeleteCompany: (tableIndex: number) => void
): ColumnDef<ScenarioValuationCompanyRow, any>[] => {
  const columnHelper = createColumnHelper<ScenarioValuationCompanyRow>();

  return [
    columnHelper.accessor('name', {
      id: 'name',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => {
        const row = props.row;
        const isExpanded = row.getIsExpanded();
        return (
          <Td
            {...props}
            align={FlexHAlign.start}
            canHide={false}
            skipExtend={true}
            className={classnames(styles.nameCellWrapper, {
              [styles.nameCellWrapperNc]: props.row.original.isCasting,
            })}
            onClick={() => {
              // allow only one row to be expanded at a time
              setExpandType(RowExpandType.Transactions);
              if (!isExpanded) {
                props.table.toggleAllRowsExpanded(false);
              }
              row.toggleExpanded();
            }}
          >
            <div className={styles.nameCellContent}>
              <ExpanderCell
                expanded={isExpanded}
                className={classnames(styles.expander, {
                  [styles.expanderExpanded]: isExpanded,
                })}
              />
              <NameCell
                bigIcon
                name={props?.getValue<string>()}
                icon={color}
                skipExtend={true}
                className={styles.nameCell}
                expanded={false}
              />
            </div>
          </Td>
        );
      },
    }),
    columnHelper.accessor('currency', {
      id: 'currency',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'100px'}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} canHide={false}>
          {fundCurrency}
        </Td>
      ),
    }),
    columnHelper.accessor('reportedSignOffCost', {
      id: 'reportedSignOffCost',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.end}
          width={'140px'}
        />
      ),
      cell: props => {
        const {value: formattedValue, suffix} = valueFormat(
          props.row.original.reportedSignOffCost,
          '.2fm'
        );

        return (
          <Td
            {...props}
            align={FlexHAlign.end}
            canHide={false}
            children={
              <span>
                {formattedValue}
                {suffix}
              </span>
            }
          />
        );
      },
    }),
    columnHelper.accessor('reportedSignOffValuation', {
      id: 'reportedSignOffValuation',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.end}
          width={'140px'}
        />
      ),
      cell: props => {
        const {value: formattedValue, suffix} = valueFormat(
          props.row.original.reportedSignOffValuation,
          '.2fm'
        );

        return (
          <Td
            {...props}
            align={FlexHAlign.end}
            canHide={false}
            children={
              <span>
                {formattedValue}
                {suffix}
              </span>
            }
          />
        );
      },
    }),
    columnHelper.accessor('nearCastCost', {
      id: 'nearCastCost',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.end}
          width={'155px'}
        />
      ),
      cell: props => {
        const {value: formattedValue, suffix} = valueFormat(
          props.row.original.nearCastCost,
          '.2fm'
        );

        return (
          <Td
            {...props}
            align={FlexHAlign.end}
            canHide={false}
            children={
              <span>
                {formattedValue}
                {suffix}
              </span>
            }
            className={classnames(styles.nearCastCostCell, {
              [styles.nearCastCostCellNc]: props.row.original.isCasting,
            })}
          />
        );
      },
    }),
    columnHelper.accessor('valuation', {
      id: 'valuation',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.end}
          width={'155px'}
        />
      ),
      cell: props => {
        const {
          getValue,
          row: {index},
          column: {id},
          table,
        } = props;
        const initialValue = getValue();
        // We need to keep and update the state of the cell normally
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [value, setValue] = React.useState(initialValue);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isEditing, setIsEditing] = React.useState(false);
        // When the input is blurred, we'll call our table meta's updateData function
        const onBlur = () => {
          if (value) {
            table?.options?.meta?.updateData?.(index, id, value);
          } else {
            table?.options?.meta?.updateData?.(index, id, initialValue);
          }
          setIsEditing(false);
        };

        // const isEdited = props.row.original.valuation !== value;
        // If the initialValue is changed external, sync it up with our state
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        const {value: formattedValue, suffix} = valueFormat(
          props.row.original.valuation,
          '.2fm'
        );

        return (
          <>
            {isEditing ? (
              <EditableCell
                name="valuation"
                value={value}
                onChange={e => {
                  if (
                    e.target.value === '' ||
                    e.target.value.match(valuationNumberRegex)
                  ) {
                    setValue(e.target.value);
                  } else {
                    e.preventDefault();
                  }
                }}
                onBlur={onBlur}
                isRight
              />
            ) : (
              <Td
                {...props}
                onDoubleClick={() => setIsEditing(true)}
                align={FlexHAlign.end}
                canHide={false}
                children={
                  <span>
                    {formattedValue}
                    {suffix}
                  </span>
                }
                className={classnames(styles.editableCell, {
                  [styles.editableCellNc]: props.row.original.isCasting,
                })}
              />
            )}
          </>
        );
      },
    }),
    columnHelper.accessor('comment', {
      id: 'comment',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          // width={'max(100vw*0.18, 150px)'}
        />
      ),
      cell: props => {
        const {
          getValue,
          row: {index},
          column: {id},
          table,
        } = props;
        const initialValue = getValue();
        // We need to keep and update the state of the cell normally
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [value, setValue] = React.useState(initialValue);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isEditing, setIsEditing] = React.useState(false);
        // When the input is blurred, we'll call our table meta's updateData function
        const onBlur = () => {
          if (value !== initialValue) {
            table.options.meta?.updateData?.(index, id, value);
          }
          setIsEditing(false);
        };
        // If the initialValue is changed external, sync it up with our state
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        return (
          <>
            {isEditing ? (
              <EditableCell
                name="comment"
                value={value}
                onChange={e => {
                  setValue(e.target.value);
                }}
                onBlur={onBlur}
                maxLength={100}
              />
            ) : (
              <Td
                {...props}
                onDoubleClick={() => setIsEditing(true)}
                align={FlexHAlign.start}
                canHide={false}
                className={classnames(styles.editableCell, {
                  [styles.editableCellNc]: props.row.original.isCasting,
                })}
              >
                <CommentCell comment={props.row.original.comment} />
              </Td>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor(_ => '', {
      id: 'dots',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.center}
          width={'24px'}
        />
      ),
      cell: props => {
        const row = props.row;
        const isExpanded = row.getIsExpanded();
        return row.getCanExpand() ? (
          <Td
            {...props}
            align={FlexHAlign.center}
            canHide={false}
            width={'24px'}
            className={classnames(styles.dotsMenu, {
              [styles.dotsMenuNc]: props.row.original.isCasting,
            })}
            children={
              row.original?.isCasting ? (
                <DotsMenuCell
                  menuItems={[
                    {
                      label: 'Edit',
                      action: () => {
                        setExpandType(RowExpandType.Edit);
                        if (!isExpanded) {
                          props.table.toggleAllRowsExpanded(false);
                          row.toggleExpanded();
                        }
                      },
                    },
                    {
                      label: 'Delete',
                      action: () => {
                        handleDeleteCompany(row.index);
                      },
                      isDelete: true,
                    },
                  ]}
                />
              ) : null
            }
          />
        ) : null;
      },
    }),
  ];
};

export default genScenarioValuationTableColumns;
